import axios from 'axios';
import axiosIns from "@/libs/axios";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAccountNames(ctx, queryParams) {
      if (ctx.rootState.user.role == 'supermanager') {
        return new Promise((resolve, reject) => {
          axiosIns
            .get('selectAccountInfo')
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      } else {
        return new Promise((resolve, reject) => {
          axiosIns
            .get('selectClientAccountInfo')
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      }
    },

    getAccGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('selectAccGroup')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccountGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('selectAccountGroups', queryParams )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },

    getHoldingsMultipleAccounts(ctx, queryParams) {
      if (ctx.rootState.user.role == 'supermanager') {
        return new Promise((resolve, reject) => {
          axiosIns
            .post('getHoldingsMultipleAccounts', queryParams )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      } else {
        return new Promise((resolve, reject) => {
          axiosIns
            .post('getHoldingsMultipleAccountsClient', queryParams )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      }
    },

    selectLatestAvailableSecurityPrices(ctx, queryParams) {
      if (ctx.rootState.user.role == 'supermanager') {
        return new Promise((resolve, reject) => {
          axiosIns
            .get('selectLatestAvailableSecurityPrices', { params: queryParams })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      } else {
        return new Promise((resolve, reject) => {
          axiosIns
            .get('selectLatestAvailableSecurityPricesClient', { params: queryParams })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      }
    },

    postAccountGroups(ctx, datas) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('postAccountGroup', datas)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    downloadPdfGet(ctx, datas) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('downloadPdfPortfolio', { data: datas }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
